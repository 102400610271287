<template>
  <div>
    <c-text-field
      v-if="!isWhatsapp || (!isWhatsapp && canVisibleAbTestInput) || (isWhatsapp && !canVisibleAbTestInput)"
      :id="seleniumDynamicFiled(seleniumIds.SELENIUM_TEST_REGISTER_NICKNAME, index)"
      :value="messenger.nickname"
      type="text"
      class="text-caption"
      hide-details="auto"
      :placeholder="messengerPlaceholder"
      v-bind="$attrs"
      :clearable="false"
      :error-messages="errors[`messengers.${index}.nickname`]"
      @input="setNickname($event)"
    >
      <template #label>
        {{ messenger.name }}
      </template>
      <template v-if="selectedMessengers.length !== 1" #append>
        <v-icon size="14" class="mr-1" @click="$emit('remove-field')">
          $close
        </v-icon>
      </template>
    </c-text-field>
    <phone-input
      v-else-if="isWhatsapp && canVisibleAbTestInput"
      :model-value="messenger.nickname"
      :error-message="$t('register.phone_input_validation_failed')"
      @input="setNickname($event)"
    />
  </div>
</template>

<script>
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import { mapActions, mapState } from 'vuex'
  import seleniumDynamicFiled from '@clickadilla/components/utils/selenium-dynamic-field.js'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import PhoneInput from '@/components/PhoneInput.vue'

  export default {
    name: 'FieldNickname',
    components: {
      PhoneInput,
      CTextField
    },
    props: {
      messenger: {
        type: Object,
        default: () => ({})
      },
      index: {
        type: Number,
        required: true
      },
      canVisibleAbTestInput: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        seleniumIds,
        phone: ''
      }
    },
    computed: {
      ...mapState('register', ['errors', 'selectedMessengers']),
      ...mapState('collections', ['messengers']),
      messengerPlaceholder() {
        return {
          Skype: 'username',
          Telegram: '@username or phone',
          Wechat: 'WeChatid or phone',
          Whatsapp: 'phone',
          Facebook: 'username',
          Line: 'Line id or phone',
          Email: 'hi@gmail.com'
        }[this.messenger.name] ?? 'username or phone'
      },
      isWhatsapp() {
        return this.messenger.name.toLowerCase() === 'Whatsapp'.toLowerCase()
      }
    },
    methods: {
      seleniumDynamicFiled,
      ...mapActions('register', ['setSelectedMessengers']),
      setNickname(nickname) {
        const copySelectedMessengers = JSON.parse(JSON.stringify(this.selectedMessengers))
        copySelectedMessengers[this.index].nickname = nickname
        this.setSelectedMessengers(copySelectedMessengers)
      }
    }
  }
</script>

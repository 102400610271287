<template>
  <div class="phone-input-container global-relative">
    <vue-tel-input
      ref="phoneInput"
      :value="modelValue"
      v-bind="options"
      :class="{ 'border-error': isError }"
      :disabled-fetching-country="disabledFetchingCountry"
      :disabled="disabled"
      :disabled-formatting="disabledFormatting"
      :only-countries="onlyCountries"
      :ignored-countries="ignoredCountries"
      :preferred-countries="preferredCountries"
      @input="(formattedNumber, phoneObject)=>onInput(formattedNumber, phoneObject)"
      @country-changed="onChangeCountry($event)"
      @focus="onFocus()"
      @blur="onBlur()"
    />
    <errors v-if="isError" :error-messages="errorMessage" />
  </div>
</template>

<script>
  import 'vue-tel-input/dist/vue-tel-input.css'
  import { VueTelInput } from 'vue-tel-input'
  import Errors from '@clickadilla/components/ui/Errors.vue'

  const ERROR_MIN_LENGTH = 4

  export default {
    name: 'PhoneInput',
    components: {
      Errors,
      VueTelInput
    },
    props: {
      modelValue: {
        type: String,
        default: ''
      },
      onlyCountries: {
        type: Array,
        default: () => []
      },
      ignoredCountries: {
        type: Array,
        default: () => []
      },
      preferredCountries: {
        type: Array,
        default: () => []
      },
      disabledFormatting: {
        type: Boolean,
        default: true
      },
      disabledFetchingCountry: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      errorMessage: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        isValid: false,
        isFocused: false,
        country: {
          areaCode: null,
          dialCode: '',
          iso2: '',
          name: '',
          priority: 0
        },
        options: {
          inputOptions: {
            showDialCode: true
          },
          name: 'telephone',
          autoFormat: true,
          dropdownOptions: {
            showSearchBox: true,
            showFlags: true,
            showDialCodeInList: true,
            showDialCodeInSelection: false
          },
          mode: 'international',
          validCharactersOnly: true
        }
      }
    },
    computed: {
      isError() {
        return (
          !this.isValid
          && !this.isFocused
          && this.modelValue.length > this.country.dialCode.length + 1
          && this.modelValue.length > ERROR_MIN_LENGTH
        )
      }
    },
    methods: {
      onFocus() {
        this.isFocused = true
      },
      onBlur() {
        this.isFocused = false

        this.$nextTick(() => {
          if (this.modelValue && !this.modelValue.startsWith('+')) {
            this.$emit('input', `+${this.modelValue}`)
          }
        })
      },
      onChangeCountry(data) {
        this.country = data
      },
      onInput(formattedNumber, phoneObject) {
        if (this.$refs.phoneInput?.cleanInvalidCharacters) {
          this.$refs.phoneInput.cleanInvalidCharacters()
        }

        if (!formattedNumber.startsWith('+')) {
          formattedNumber = `+${formattedNumber}`
        }

        this.isValid = phoneObject?.valid ?? false

        this.$emit('input', formattedNumber)
      }
    }
  }
</script>

<style scoped lang="scss">
  .border-error {
    border: 1px solid var(--v-error-base) !important;
  }

  .phone-input-container {
    & .vue-tel-input {
      height: 42px;
      background-color: var(--v-secondary-lighten-base);
      border: 1px solid transparent;

      &:hover {
        border-color: var(--v-primary-base);
      }

      &:focus,
      &:focus-within,
      &:focus-visible {
        border-color: var(--v-primary-base);
        outline: 1px solid var(--v-primary-base);
        box-shadow: none;
      }

      & ::v-deep .vti__dropdown-list {
        padding-left: 0 !important;
        width: 300px;
        overflow-x: hidden;
      }

      & ::v-deep .vti__dropdown {
        border-right: 1px solid rgba(0, 0, 0, 0.38);
      }

      & ::v-deep .vti__search_box {
        width: 100%;
        margin: 0;
        margin-bottom: 2px;
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.38);
      }

      & ::v-deep .vti__dropdown-item.highlighted {
        position: relative;
        background-color: transparent;
        color: var(--v-primary-base);

        &::before {
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          background-color: var(--v-primary-base);
          opacity: 0.16;
          top: 0;
          left: 0;
        }
      }
    }
  }
</style>
